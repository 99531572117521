<template>
  <v-slide-y-reverse-transition>
    <v-card v-show="showMessengerPopup" :dark="dark" width="320" :height="height" class="messenger-popup">
      <v-toolbar dense flat class="toolbar" :class="{ clickable: collapsable }" @click="toggleExpanded">
        <div class="d-flex flex-column">
          <div class="messenger-title font-weight-bold subtitle-1 text-truncate">{{ patientName }}</div>
          <div v-if="patientSmsPhone" class="font-weight-regular caption mt-n2">{{ patientSmsPhone }}</div>
        </div>
        <v-spacer></v-spacer>
        <div
          v-if="unreadMessagesCount && !expanded"
          class="rounded-lg primary body-2 white--text text-center font-weight-bold px-2 py-1 mr-2"
          style="min-width: 30px"
        >
          {{ unreadMessagesCount }}
        </div>
        <div v-if="collapsable" class="expand-icon">
          <v-icon v-if="expanded" left>mdi-chevron-down</v-icon>
          <v-icon v-else left>mdi-chevron-up</v-icon>
        </div>
        <v-icon v-if="closeable" @click.stop="handleClose">mdi-close</v-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="messenger-container">
        <!-- This example uses the web worker -->
        <messenger
          ref="messenger"
          v-if="status === 'ready'"
          :content="content"
          v-model="messages"
          @submit="handleSendMessage"
        />
        <div v-else-if="status === 'busy'" class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<style lang="scss" scoped>
.messenger-popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  border-radius: 10px;
}

.messenger-container {
  height: calc(100% - 48px);
  overflow-y: hidden;
}
.messenger-title {
  width: 180px;
}
.clickable {
  cursor: pointer;
}
.expand-icon {
  opacity: 0.5;
}
.caption {
  opacity: 0.8;
}

.toolbar {
  z-index: 1;
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ddd;
  opacity: 0.3;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>

<script>
import Messenger from '../../shared-ui/components/messenger/Messenger.vue'
import get from 'lodash/get'
import bus from '@/core/helpers/bus'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Messenger,
  },
  props: {
    collapsable: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    scrollDuration: {
      type: Number,
      default: 500,
    },
    scrollToBottomDistance: {
      type: Number,
      default: 120,
    },
  },
  data() {
    return {
      content: '',
      expanded: true,
      $messages: [],
      patient: null,
      status: 'busy',
      showMessengerPopup: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('org', ['org']),
    ...mapState('conversation', ['conversation', 'messages', 'unreadMessagesCount']),
    height() {
      return this.expanded && !this.collapsed ? 480 : 48
    },
    patientId() {
      return get(this.patient, 'id') || get(this.patient, 'id')
    },
    patientName() {
      return String(get(this.patient, 'firstName', '') + ' ' + get(this.patient, 'lastName', '')).trim() || ''
    },
    patientSmsPhone() {
      return get(this.patient, 'smsPhone', '')
    },
  },
  watch: {
    showMessengerPopup(val) {
      this.$emit('messengerOpen', val)
    },
    unreadMessagesCount(newCount, oldCount) {
      if (newCount > oldCount && this.showMessengerPopup && this.expanded) {
        this.startMessagesCheck()
      }
    },
    expanded(val) {
      if (val) {
        this.$nextTick(this.startMessagesCheck)
      }
    },
  },
  methods: {
    ...mapActions('conversation', ['decMessengerOpenCount', 'incMessengerOpenCount', 'getMessages', 'sendMessage']),
    handleOpen({ patient, content = '' }) {
      if (!this.showMessengerPopup) {
        this.content = content
        this.status = 'busy'
        this.patient = patient
        this.showMessengerPopup = true
        this.$nextTick(this.startMessagesCheck)
        this.incMessengerOpenCount()
      }
      this.expanded = true
    },
    handleClose() {
      this.showMessengerPopup = false
      this.patient = null
      this.status = ''
      this.decMessengerOpenCount()
    },
    handleSendMessage(content) {
      let patientId = this.patientId
      if (patientId) {
        this.sendMessage({
          displayName: this.user.firstName + ' ' + this.user.lastName,
          orgId: this.org.id,
          patientId,
          content,
        })
      } else {
        console.warn('PatientMessengerPopup.handleSendMessage: no patient id found')
      }
    },
    async startMessagesCheck() {
      let patientId = this.patientId
      if (patientId) {
        await this.getMessages({
          orgId: this.org.id,
          patientId,
        })
        // TODO: interval to pull messages
        this.status = 'ready'
      } else {
        console.warn('PatientMessengerPopup.startMessagesCheck: no patient id found')
      }
    },
    invalidate() {
      this.$refs.messenger.updateSize()
    },
    toggleExpanded() {
      if (this.collapsable) {
        this.expanded = !this.expanded
      }
    },
  },
  mounted() {
    bus.$on('messenger:open', this.handleOpen)
  },
  beforeDestroy() {
    if (this.showMessengerPopup) {
      this.handleClose()
    }
    bus.$off('messenger:open', this.handleOpen)
  },
}
</script>
