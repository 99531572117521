<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            style="background-color: #f5f5f5"
            width="36"
            height="36"
            depressed
            rounded
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
          >
            <v-icon size="18">mdi-cloud-print-outline</v-icon>
          </v-btn>
        </template>
        <span>Export Facesheet</span>
      </v-tooltip>
    </template>

    <v-card class="pb-4">
      <v-row align="center" no-gutters justify="space-between" class="font-weight-bold text-h6 pa-3 px-4">
        <span>Export Facesheet</span>
        <v-btn icon @click="menu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-card-text class="pb-3 px-4">
        The patient facesheet contains basic patient demographics and lists the most recent measurements in the
        patient's chart. The facesheet can be generated for the previous 1, 3, 6, or 12 months. Selecting a range below
        and choosing export will generate a PDF for printing.
      </v-card-text>

      <v-select
        class="pt-0 px-4 mb-3 mx-0"
        hide-details
        background-color="grey lighten-4"
        full-width
        single-line
        solo
        flat
        :items="rangeSelectionOptions"
        item-text="title"
        v-model="selectedOption"
        return-object
      >
      </v-select>

      <v-row class="px-4 pt-4" justify="space-between" no-gutters>
        <v-btn depressed @click="menu = false">Cancel</v-btn>
        <v-btn color="primary" depressed @click="sendExport()">Export</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'

export default {
  mixins: [HelperMixin],
  name: 'addChartNoteMenu',
  props: {
    enablePopups: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu: false,
      refPoint: null,
      taggedUser: null,
      rangeOptions: ['1 Month', '3 Months', '6 Months', '1 Year'],
      selectedOption: null,
    }
  },
  methods: {
    ...mapActions('patient', ['exportFacesheet']),
    sendExport() {
      this.exportFacesheet({ patient: this.patient, start: this.selectedOption.start }).then(() => {
        this.menu = false
      })
    },
  },
  computed: {
    ...mapState('patient', ['patient']),
    rangeSelectionOptions() {
      let options = []
      let yearStart = new Date()
      yearStart.setHours(0, 0, 0, 0)
      yearStart.setDate(yearStart.getDate() - 365)
      let year = { title: 'One Year', start: yearStart.getTime(), stats: 'yearStats' }

      let sixMonthStart = new Date()
      sixMonthStart.setHours(0, 0, 0, 0)
      sixMonthStart.setDate(sixMonthStart.getDate() - 31 * 6)
      let sixMonths = { title: '6 Months', start: sixMonthStart.getTime(), stats: 'sixMonthStats' }
      year.bucketCutoff = sixMonthStart.getTime()

      let threeMonthStart = new Date()
      threeMonthStart.setHours(0, 0, 0, 0)
      threeMonthStart.setDate(threeMonthStart.getDate() - 31 * 3)
      let threeMonths = { title: '3 Months', start: threeMonthStart.getTime(), stats: 'threeMonthStats' }
      sixMonths.bucketCutoff = threeMonthStart.getTime()

      let oneMonthStart = new Date()
      oneMonthStart.setHours(0, 0, 0, 0)
      oneMonthStart.setDate(oneMonthStart.getDate() - 31)
      let oneMonth = { title: '1 Month', start: oneMonthStart.getTime(), stats: 'oneMonthStats' }
      threeMonths.bucketCutoff = oneMonthStart.getTime()
      options.push(oneMonth)
      options.push(threeMonths)
      options.push(sixMonths)
      options.push(year)
      return options
    },
  },
  watch: {
    menu(val) {
      if (val) return
      this.refPoint = null
      this.taggedUser = null
    },
  },
  mounted() {
    this.selectedOption = this.rangeSelectionOptions[0]
  },
}
</script>
