<template>
  <v-dialog v-model="show" :max-width="tryNew ? 600 : 500" scrollable>
    <v-card color="white">
      <v-row class="pa-4" align="center" no-gutters>
        <v-avatar size="40" class="mr-3" color="primary"
          ><span v-if="user" class="font-weight-bold white--text" style="font-size: 16px"
            >{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}</span
          ></v-avatar
        >
        <v-col>
          <div style="font-weight: bold; margin-bottom: -2px">{{ user.firstName }} {{ user.lastName }}</div>
          <div style="font-size: 14px; margin-top: -2px">Right now</div>
        </v-col>
        <v-spacer />
        <v-btn small :color="tryNew ? 'grey darken-2' : 'primary'" :text="tryNew" @click="tryNew = !tryNew" depressed>
          <span v-if="!tryNew"> try new</span> <span v-else> back to old</span></v-btn
        >
        <v-btn @click="show = false" large icon><v-icon>mdi-close</v-icon></v-btn>
      </v-row>
      <div v-if="!tryNew">
        <v-row v-show="noteTemplates.length > 0" no-gutters>
          <v-select
            class="pt-0 px-4 mb-3 mx-0"
            hide-details
            background-color="grey lighten-3"
            full-width
            single-line
            solo
            flat
            placeholder="Insert template"
            prepend-inner-icon="mdi-code-braces"
            :items="noteTemplates"
            item-text="title"
            v-model="selectedTemplate"
            clearable
            return-object
            dense
            @change="templateSelected"
            :append-icon="selectedTemplate !== null ? null : 'mdi-chevron-down'"
          >
          </v-select>
        </v-row>

        <v-autocomplete
          class="pt-0 px-4 mb-3 mx-0"
          hide-details
          background-color="grey lighten-3"
          full-width
          single-line
          solo
          flat
          placeholder="Tag users"
          prepend-inner-icon="mdi-at"
          :items="usersForSelection"
          item-text="text"
          item-value="value"
          dense
          v-model="mentions"
          clearable
          auto-select-first
          :append-icon="mentions && mentions.length !== 0 ? null : 'mdi-chevron-down'"
          multiple
          :search-input.sync="userSearchInput"
          @change="userSearchInput = null"
        >
          <template v-slot:selection="data">
            <v-chip small v-bind="data.attrs" :input-value="data.selected">
              <v-avatar size="20" left>
                <v-icon small>mdi-account</v-icon>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>

      <v-row v-else class="px-4 mb-1" no-gutters>
        <v-card color="grey lighten-4" flat class="flex">
          <v-row align="center" no-gutters>
            <v-autocomplete
              hide-details
              background-color="transparent"
              single-line
              solo
              flat
              placeholder="Tag users"
              prepend-inner-icon="mdi-at"
              :items="usersForSelection"
              item-text="text"
              item-value="value"
              v-model="mentions"
              clearable
              auto-select-first
              :append-icon="mentions && mentions.length !== 0 ? null : 'mdi-chevron-down'"
              multiple
              :search-input.sync="userSearchInput"
              @change="userSearchInput = null"
              :menu-props="{ minWidth: 566 }"
            >
              <template v-slot:selection="{ item, index }">
                <span class="text-truncate" style="max-width: 200px" v-if="index === 0"> {{ item.name }}</span>
                <span style="max-width: 100px; padding-left: 5px" v-if="index === 1">
                  + {{ mentions.length - 1 }} more</span
                >
              </template>
            </v-autocomplete>
            <v-divider vertical />
            <v-menu v-show="noteTemplates.length > 0" :nudge-left="48" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :style="selectedTemplate ? 'background-color: #E0E0E0' : null"
                  tile
                  large
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon v-if="!selectedTemplate">mdi-code-braces</v-icon>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon color="primary" v-if="selectedTemplate">mdi-code-braces</v-icon>
                  </v-scale-transition>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click=";(selectedTemplate = item), templateSelected()"
                  v-for="(item, index) in noteTemplates"
                  :key="index"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              @click="flagForProviderReview = !flagForProviderReview"
              class="px-1"
              tile
              large
              icon
              :style="flagForProviderReview ? 'background-color: #E0E0E0' : null"
            >
              <v-scale-transition hide-on-leave origin="center, center">
                <v-icon v-if="!flagForProviderReview">mdi-flag-outline</v-icon>
              </v-scale-transition>
              <v-scale-transition hide-on-leave origin="center, center">
                <v-icon color="red" v-if="flagForProviderReview">mdi-flag</v-icon>
              </v-scale-transition>
            </v-btn>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-left="240"
              offset-y
              min-width="auto"
              origin="top, right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn :style="date ? 'background-color: #E0E0E0' : null" tile large v-bind="attrs" v-on="on" icon>
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon v-if="!date">mdi-calendar</v-icon>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon color="primary" v-if="date">mdi-calendar</v-icon>
                  </v-scale-transition>
                </v-btn>
              </template>
              <v-date-picker no-title v-model="date" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-row>
        </v-card>
      </v-row>
      <v-card-text class="pa-2 pt-0">
        <v-card class="flex" min-height="300" rounded="lg" flat color="white">
          <v-textarea
            ref="textInput"
            background-color="white"
            hide-details
            solo
            flat
            placeholder="Add a note..."
            height="250"
            :rows="15"
            class="text-body"
            v-model="newNoteInput"
            @keydown.enter.prevent=""
            @keyup.enter.prevent="submitAndClose()"
            no-resize
            autofocus
          >
          </v-textarea>
        </v-card>
        <v-expand-transition v-if="!tryNew">
          <div style="height: 65px">
            <v-row class="fill-height px-3" align="center" no-gutters>
              <v-fade-transition>
                <v-checkbox
                  label="For Provider Review"
                  color="primary"
                  depressed
                  v-model="flagForProviderReview"
                  :disabled="!newNoteInput || newNoteInput === ''"
                ></v-checkbox>
              </v-fade-transition>
              <v-spacer />
              <v-fade-transition>
                <v-btn
                  depressed
                  :color="newNoteInput && newNoteInput !== '' ? 'primary' : 'grey lighten-2'"
                  @click="submitAndClose()"
                  :disabled="!newNoteInput || newNoteInput === ''"
                  >Post</v-btn
                >
              </v-fade-transition>
            </v-row>
          </div>
        </v-expand-transition>
        <v-expand-transition v-else>
          <div style="height: 65px">
            <v-row class="fill-height px-3" align="center" no-gutters>
              <v-scale-transition origin="center, center" hide-on-leave>
                <v-chip
                  class="mr-2"
                  v-if="selectedTemplate !== null"
                  @click:close="selectedTemplate = null"
                  close
                  label
                >
                  <v-icon left>mdi-code-braces</v-icon
                  ><span class="text-truncate" style="max-width: 80px">{{ selectedTemplate.title }}</span>
                </v-chip>
              </v-scale-transition>
              <v-scale-transition origin="center, center" hide-on-leave>
                <v-chip class="mr-2" v-if="date !== null" @click:close="date = null" close label>
                  <v-icon small left>mdi-calendar</v-icon>{{ date }}
                </v-chip>
              </v-scale-transition>
              <v-scale-transition origin="center, center" hide-on-leave>
                <v-chip v-if="flagForProviderReview" @click:close="flagForProviderReview = false" close label
                  ><v-icon small left>mdi-flag</v-icon>Provider Review</v-chip
                >
              </v-scale-transition>

              <v-spacer />
              <v-fade-transition>
                <v-btn
                  depressed
                  :color="newNoteInput && newNoteInput !== '' ? 'primary' : 'grey lighten-2'"
                  @click="submitAndClose()"
                  :disabled="!newNoteInput || newNoteInput === ''"
                  >Post</v-btn
                >
              </v-fade-transition>
            </v-row>
          </div>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <v-card v-if="tryNew && false" color="white">
      <v-row class="pa-4" align="center" no-gutters>
        <v-avatar size="40" class="mr-3" color="primary"
          ><span v-if="user" class="font-weight-bold white--text" style="font-size: 16px"
            >{{ user.firstName.charAt(0) }}{{ user.lastName.charAt(0) }}</span
          ></v-avatar
        >
        <v-col>
          <div style="font-weight: bold; margin-bottom: -2px">{{ user.firstName }} {{ user.lastName }}</div>
          <div style="font-size: 14px; margin-top: -2px">Right now</div>
        </v-col>
        <v-spacer />
        <v-btn @click="tryNew = !tryNew" text>New</v-btn>
        <v-btn @click="show = false" large icon><v-icon>mdi-close</v-icon></v-btn>
      </v-row>

      <v-card-text class="pa-2 pt-0">
        <v-card class="flex" min-height="300" rounded="lg" flat color="white">
          <v-textarea
            ref="textInput"
            background-color="white"
            hide-details
            solo
            flat
            placeholder="Add a note..."
            height="250"
            :rows="15"
            class="text-body"
            v-model="newNoteInput"
            @keydown.enter.prevent=""
            @keyup.enter.prevent="submitAndClose()"
            no-resize
            autofocus
          >
          </v-textarea>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { mapActions, mapState } from 'vuex'
import { prepMetric } from '@/helpers/metric'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      newNote: false,
      newNoteInput: null,
      flagForProviderReview: false,
      thresholdAlertsToClear: [],
      show: false,
      selectedTemplate: null,
      mentions: [],
      userSearchInput: null,
      inputRows: 6,
      date: null,
      menu: false,
      tryNew: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('patient', ['patient']),
    ...mapState('org', ['settings', 'users']),
    noteTemplates() {
      if (this.settings && this.settings.chartNoteTemplates) {
        return Object.values(this.settings.chartNoteTemplates).sort((a, b) => a.title.localeCompare(b.title))
      }
      return []
    },
    usersForSelection() {
      let selections = []

      this.users.forEach(user => {
        let object = {
          name: user.firstName + ' ' + user.lastName,
          text: user.firstName + ' ' + user.lastName + ' | ' + user.email,
          value: user.id,
        }
        selections.push(object)
      })

      return selections
    },
  },
  props: {
    dialogOpen: Boolean,
  },
  watch: {
    dialogOpen(val) {
      this.show = val
    },
    show(val) {
      this.$emit('dialogOpen', val)
    },
    newNoteInput() {
      let textArea = this.$refs.textInput
      console.log(textArea.$el.scrollHeight)
    },
  },
  methods: {
    ...mapActions('patient', ['addNote', 'updateTags']),

    templateSelected() {
      if (this.selectedTemplate) {
        if (this.newNoteInput !== null) {
          this.newNoteInput = this.newNoteInput += this.selectedTemplate.content
        } else {
          this.newNoteInput = this.selectedTemplate.content
        }
      }
    },
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    close() {
      this.show = false
    },
    async submitAndClose() {
      this.submitNote()
      this.close()
    },
    async submitNote() {
      if (this.newNoteInput === null) return
      const noteText = this.newNoteInput.replace(/[\n\r]/g, '')
      this.newNoteInput = null
      if (noteText === '') return
      const noteTags = this.flagForProviderReview ? ['Provider Review'] : []
      if (this.thresholdAlertsToClear) {
        this.thresholdAlertsToClear.forEach(threshold => {
          noteTags.push('clearedAlert:' + threshold)
        })
      }

      if (this.selectedTemplate) {
        console.log(this.selectedTemplate)
        noteTags.push(this.selectedTemplate.id)
      }
      let tickler = null
      if (this.date) {
        tickler = Date.parse(this.date) + new Date().getTimezoneOffset() * 60000
      }

      // 1. Add the note
      try {
        noteText
        await this.addNote({
          text: noteText,
          tags: noteTags,
          userMentions: this.mentions,
          alertsToClear: this.thresholdAlertsToClear,
          tickler: tickler,
        })
      } catch (error) {
        console.error(error)
        bus.$emit('toast', { type: 'error', text: 'Error saving note: ' + error })
        return
      }

      bus.$emit('toast', { type: 'success', text: 'Note Saved' })
    },
    openNewNote(payload) {
      this.date = null
      this.mentions = []
      this.newNoteInput = null
      this.flagForProviderReview = false
      this.thresholdAlertsToClear = []
      this.selectedTemplate = null

      if (payload.notePayload && payload.notePayload.notePrompt) {
        this.newNoteInput = payload.notePayload.notePrompt
        // this may hav threshold alerts to clear
        if (payload.notePayload.payload) {
          this.thresholdAlertsToClear = payload.notePayload.payload
        }
      }
      if (payload.notePayload && payload.notePayload.type === 'metric') {
        // this is from the chart
        const value = payload.notePayload.data
        prepMetric(value)
        this.thresholdAlertsToClear = [value.id]
        this.newNoteInput =
          this.patient.firstName +
          "'s " +
          value.kind +
          ' was ' +
          value.displayValue +
          ' ' +
          value.units +
          ' on ' +
          value.readableDate +
          '. '
      }
      this.show = true
    },
  },
}
</script>
