<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        rounded
        :disabled="!userCanTrackTime"
        :color="isTracking ? 'green' : 'grey'"
        :dark="isTracking"
        @click="toggleTime"
        depressed
        :text="!isTracking"
      >
        <v-icon left class="mr-2">{{ isTracking ? 'mdi-stop' : 'mdi-clock-outline' }}</v-icon>
        {{ clockDisplay }}
      </v-btn>
    </template>
    <span>{{ isTracking ? 'Stop Timer' : 'Start Timer' }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import { since as idleSince } from '@/helpers/idle'

const msMinute = 60000

export default {
  data() {
    return {
      currentPtId: null,
      isTracking: false,
      sessionHeartbeatInterval: null,
      sessionPatientId: null,
      sessionStartTime: null,
      clockUpdateInterval: null,
      clockDisplay: '00:00',
    }
  },
  computed: {
    ...mapGetters('auth', ['isOrgQhp']),
    ...mapState('org', ['settings']),
    ...mapState('patient', ['patient']),
    userCanTrackTime() {
      return !!(this.patient.rpm.enrollment?.enrolled && this.isOrgQhp);
    },
    autoCloseMs() {
      return (this.settings.chartInactivity || 0) * msMinute
    },
  },
  methods: {
    ...mapActions('rpm', ['clearSession', 'sessionHeartbeat']),
    clockUpdate() {
      const elapsedMs = new Date() - this.sessionStartTime
      const minutes = Math.floor((elapsedMs % 3600000) / 60000)
      const seconds = Math.floor((elapsedMs % 60000) / 1000)
      this.clockDisplay = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    },
    async doSessionHeartbeat() {
      const idleMs = new Date() - idleSince
      if (idleMs >= this.autoCloseMs) return // edge case: user has been idle too long (perhaps from locking computer), but chart hasn't auto closed yet. Don't heartbeat.
      await this.sessionHeartbeat({ patientId: this.sessionPatientId })
    },
    toggleTime() {
      if (!this.userCanTrackTime) return
      if (!this.isTracking) {
        this.startTrackingTime()
      } else {
        this.endTrackingTime()
        bus.$emit('toast', { type: 'error', text: 'Timer Stopped' })
      }
    },
    startTrackingTime() {
      this.sessionStartTime = new Date()
      this.sessionPatientId = this.currentPtId
      this.doSessionHeartbeat()
      this.sessionHeartbeatInterval = setInterval(this.doSessionHeartbeat, 30000)
      this.clockUpdateInterval = setInterval(this.clockUpdate, 1000)
      this.isTracking = true
    },
    endTrackingTime() {
      clearInterval(this.sessionHeartbeatInterval)
      clearInterval(this.clockUpdateInterval)
      this.doSessionHeartbeat() // one last heartbeat to capture remaining time

      // clear out timer state
      this.clockDisplay = '00:00'
      this.sessionStartTime = null
      this.sessionPatientId = null
      this.clearSession()
      this.isTracking = false
    },
  },
  watch: {
    patient() {
      if (this.patient.id === this.currentPtId) return // do nothing if same patient (e.g. on patient refresh)
      this.currentPtId = this.patient.id

      if (this.isTracking) this.endTrackingTime()
      if (this.settings.autoCounting) this.toggleTime()
    },
  },
  mounted() {
    this.currentPtId = this.patient.id
    if (this.settings.autoCounting) this.toggleTime()
  },
  beforeDestroy() {
    if (this.isTracking) this.endTrackingTime()
  },
}
</script>
