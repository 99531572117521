<template>
  <v-slide-y-reverse-transition hide-on-leave>
    <div
      v-if="show"
      id="notecard"
      style="position: fixed; bottom: 10px; right: 10px; z-index: 30; border-radius: 10px"
      class="cardShadow"
      :style="messengerOpen ? 'right: 350px;' : 'right: 10px;'"
    >
      <v-card :loading="submitting" :width="expanded ? 600 : 300" :dark="!expanded" rounded="lg" flat>
        <v-toolbar
          dense
          dark
          id="notecardheader"
          flat
          class="font-weight-bold"
          style="font-size: 20px"
          :style="submitting ? 'opacity: 0.3' : null"
        >
          New Note
          <v-spacer />
          <v-btn @click="expanded = !expanded" icon><v-icon>mdi-minus</v-icon></v-btn>
          <v-btn @click="close" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <div :style="submitting ? 'opacity: 0.3' : null" v-if="expanded">
          <v-row no-gutters align="center">
            <v-autocomplete
              hide-details
              background-color="transparent"
              single-line
              solo
              flat
              placeholder="Tag users"
              prepend-inner-icon="mdi-at"
              :items="usersForSelection"
              item-text="text"
              item-value="value"
              v-model="mentions"
              clearable
              auto-select-first
              :append-icon="mentions && mentions.length !== 0 ? null : 'mdi-chevron-down'"
              multiple
              :search-input.sync="userSearchInput"
              @change="userSearchInput = null"
            >
              <template v-slot:selection="{ item, index }">
                <span class="text-truncate" style="max-width: 200px" v-if="index === 0"> {{ item.name }}</span>
                <span style="max-width: 100px; padding-left: 5px" v-if="index === 1">
                  + {{ mentions.length - 1 }} more</span
                >
              </template>
            </v-autocomplete>

            <v-menu v-show="noteTemplates.length > 0" :nudge-left="48" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :style="selectedTemplate ? 'background-color: #E0E0E0' : null"
                  tile
                  icon
                  v-bind="attrs"
                  v-on="on"
                  style="width: 50px; height: 50px"
                >
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon v-if="!selectedTemplate">mdi-code-braces</v-icon>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon color="primary" v-if="selectedTemplate">mdi-code-braces</v-icon>
                  </v-scale-transition>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click=";(selectedTemplate = item), templateSelected()"
                  v-for="(item, index) in noteTemplates"
                  :key="index"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              @click="flagForProviderReview = !flagForProviderReview"
              tile
              icon
              :style="flagForProviderReview ? 'background-color: #E0E0E0' : null"
              style="width: 50px; height: 50px"
            >
              <v-scale-transition hide-on-leave origin="center, center">
                <v-icon v-if="!flagForProviderReview">mdi-flag-outline</v-icon>
              </v-scale-transition>
              <v-scale-transition hide-on-leave origin="center, center">
                <v-icon color="red" v-if="flagForProviderReview">mdi-flag</v-icon>
              </v-scale-transition>
            </v-btn>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-left="240"
              offset-y
              min-width="auto"
              origin="top, right"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :style="date ? 'background-color: #E0E0E0' : null"
                  tile
                  v-bind="attrs"
                  v-on="on"
                  icon
                  style="width: 50px; height: 50px"
                >
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon v-if="!date">mdi-calendar</v-icon>
                  </v-scale-transition>
                  <v-scale-transition hide-on-leave origin="center, center">
                    <v-icon color="primary" v-if="date">mdi-calendar</v-icon>
                  </v-scale-transition>
                </v-btn>
              </template>
              <v-date-picker no-title v-model="date" @input="menu = false"></v-date-picker>
            </v-menu>
          </v-row>

          <v-divider />
          <div class="pa-1 pb-1">
            <v-textarea
              ref="textInput"
              background-color="transparent"
              hide-details
              solo
              flat
              placeholder="Add a note..."
              height="400"
              :rows="15"
              class="text-body-1"
              v-model="newNoteInput"
              @keydown.enter.prevent=""
              @keyup.enter.prevent="submitAndClose()"
              no-resize
              autofocus
            >
            </v-textarea>

            <div style="height: 65px">
              <v-row class="fill-height px-3" align="center" no-gutters>
                <v-scale-transition origin="center, center" hide-on-leave>
                  <v-chip
                    class="mr-2"
                    v-if="selectedTemplate !== null"
                    @click:close="selectedTemplate = null"
                    close
                    label
                  >
                    <v-icon left>mdi-code-braces</v-icon
                    ><span class="text-truncate" style="max-width: 80px">{{ selectedTemplate.title }}</span>
                  </v-chip>
                </v-scale-transition>
                <v-scale-transition origin="center, center" hide-on-leave>
                  <v-chip class="mr-2" v-if="date !== null" @click:close="date = null" close label>
                    <v-icon small left>mdi-calendar</v-icon>{{ date }}
                  </v-chip>
                </v-scale-transition>
                <v-scale-transition origin="center, center" hide-on-leave>
                  <v-chip v-if="flagForProviderReview" @click:close="flagForProviderReview = false" close label
                    ><v-icon small left>mdi-flag</v-icon>Provider Review</v-chip
                  >
                </v-scale-transition>

                <v-spacer />
                <v-fade-transition>
                  <v-btn
                    depressed
                    :color="newNoteInput && newNoteInput !== '' ? 'primary' : 'grey lighten-2'"
                    @click="submitAndClose()"
                    :disabled="!newNoteInput || newNoteInput === ''"
                    >Submit</v-btn
                  >
                </v-fade-transition>
              </v-row>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-slide-y-reverse-transition>
</template>
<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { mapActions, mapState } from 'vuex'
import { prepMetric } from '@/helpers/metric'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      newNote: false,
      newNoteInput: null,
      flagForProviderReview: false,
      thresholdAlertsToClear: [],
      show: false,
      selectedTemplate: null,
      mentions: [],
      userSearchInput: null,
      inputRows: 6,
      date: null,
      menu: false,
      tryNew: false,
      expanded: false,
      visible: false,
      submitting: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('patient', ['patient']),
    ...mapState('org', ['settings', 'users']),
    noteTemplates() {
      if (this.settings && this.settings.chartNoteTemplates) {
        return Object.values(this.settings.chartNoteTemplates).sort((a, b) => a.title.localeCompare(b.title))
      }
      return []
    },
    usersForSelection() {
      let selections = []

      this.users.forEach(user => {
        let object = {
          name: user.firstName + ' ' + user.lastName,
          text: user.firstName + ' ' + user.lastName + ' | ' + user.email,
          value: user.id,
        }
        selections.push(object)
      })

      return selections
    },
  },
  props: {
    dialogOpen: Boolean,
    messengerOpen: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show() {
      this.expanded = true
    },
  },
  methods: {
    ...mapActions('patient', ['addNote', 'updateTags']),
    templateSelected() {
      if (this.selectedTemplate) {
        if (this.newNoteInput !== null) {
          this.newNoteInput = this.newNoteInput += this.selectedTemplate.content
        } else {
          this.newNoteInput = this.selectedTemplate.content
        }
      }
    },
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    close() {
      this.expanded = false
      this.show = false
    },
    async submitAndClose() {
      this.submitting = true
      await this.submitNote()
      this.submitting = false
      this.close()
    },
    async submitNote() {
      if (this.newNoteInput === null) return
      const noteText = this.newNoteInput.replace(/[\n\r]/g, '')

      if (noteText === '') return
      const noteTags = this.flagForProviderReview ? ['Provider Review'] : []
      if (this.thresholdAlertsToClear) {
        this.thresholdAlertsToClear.forEach(threshold => {
          noteTags.push('clearedAlert:' + threshold)
        })
      }

      if (this.selectedTemplate) {
        console.log(this.selectedTemplate)
        noteTags.push(this.selectedTemplate.id)
      }
      let tickler = null
      if (this.date) {
        tickler = Date.parse(this.date) + new Date().getTimezoneOffset() * 60000
      }

      // 1. Add the note
      try {
        noteText
        await this.addNote({
          text: noteText,
          tags: noteTags,
          userMentions: this.mentions,
          alertsToClear: this.thresholdAlertsToClear,
          tickler: tickler,
        })
        this.newNoteInput = null
      } catch (error) {
        console.error(error)
        bus.$emit('toast', { type: 'error', text: 'Error saving note: ' + error })
        return
      }

      bus.$emit('toast', { type: 'success', text: 'Note Saved' })
    },
    openNewNote(payload) {
      this.visible = true
      this.date = null
      this.mentions = []
      this.newNoteInput = null
      this.flagForProviderReview = false
      this.thresholdAlertsToClear = []
      this.selectedTemplate = null

      if (payload.notePayload && payload.notePayload.notePrompt) {
        this.newNoteInput = payload.notePayload.notePrompt
        // this may hav threshold alerts to clear
        if (payload.notePayload.payload) {
          this.thresholdAlertsToClear = payload.notePayload.payload
        }
      }
      if (payload.notePayload && payload.notePayload.type === 'metric') {
        // this is from the chart
        const value = payload.notePayload.data
        prepMetric(value)
        this.thresholdAlertsToClear = [value.id]
        this.newNoteInput =
          this.patient.firstName +
          "'s " +
          value.kind +
          ' was ' +
          value.displayValue +
          ' ' +
          value.units +
          ' on ' +
          value.readableDate +
          '. '
      }
      this.show = true
    },
    dragElement(elmnt) {
      var pos1 = 0,
        //pos2 = 0,
        pos3 = 0
      //pos4 = 0
      if (document.getElementById(elmnt.id + 'header')) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown
      }

      function dragMouseDown(e) {
        e = e || window.event
        e.preventDefault()
        // get the mouse cursor position at startup:
        pos3 = e.clientX
        //pos4 = e.clientY
        document.onmouseup = closeDragElement
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag
      }

      function elementDrag(e) {
        e = e || window.event
        e.preventDefault()
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX
        //pos2 = pos4 - e.clientY
        pos3 = e.clientX
        //pos4 = e.clientY
        // set the element's new position:
        //elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
        console.log(window.innerWidth)
        if (elmnt.offsetLeft - pos1 <= 440) {
          elmnt.style.left = '440px'
        } else if (elmnt.offsetLeft - pos1 >= window.innerWidth - 630) {
          elmnt.style.left = window.innerWidth - 630 + 'px'
        } else {
          elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null
        document.onmousemove = null
      }
    },
  },
  mounted() {
    //this.dragElement(document.getElementById('notecard'))
  },
}
</script>

<style scoped>
.cardShadow {
  box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.28);
}
</style>
