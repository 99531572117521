<template>
  <v-card rounded="lg" class="flex pa-3" flat :color="cardDetails.cardColor" :dark="cardDetails.cardDark">
    <v-row class="mb-1" align="start" no-gutters>
      <v-avatar size="22" :color="cardDetails.avatarBackground">
        <v-icon size="14">{{ cardDetails.icon }}</v-icon>
      </v-avatar>
      <v-col class="pl-2">
        <v-row no-gutters>
          <span style="font-weight: bold">{{ notification.title }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-card-text class="pa-0">
        <span>{{ notification.text }}</span>
      </v-card-text>
    </v-row>
    <v-row class="pt-2" no-gutters>
      <v-col v-if="cardDetails.dismissAction" :class="cardDetails.actionButton ? 'pr-1' : null">
        <v-btn block @click="$emit('handleDismiss')" small depressed :color="cardDetails.dismissColor"
          ><v-icon left small>mdi-check-circle</v-icon>{{ cardDetails.dismissActionText }}</v-btn
        >
      </v-col>
      <v-col v-if="cardDetails.actionButton" :class="cardDetails.dismissAction ? 'pl-1' : null">
        <v-btn
          block
          @click="$emit('handleAction')"
          :color="cardDetails.actionColor"
          :light="cardDetails.actionLight"
          small
          depressed
          ><v-icon left small>mdi-file-document-edit</v-icon>Note</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      default: () => {
        return {
          title: 'Notification title',
          text: 'Notification payload',
          type: 'Threshold Breach',
          payload: { detail: 'payload' },
        }
      },
    },
  },
  setup(props) {
    const cardDetails = computed(() => {
      if (props.notification.type === 'newPatient') {
        return {
          icon: 'mdi-account-plus',
          cardDark: true,
          cardColor: 'primary',
          avatarBackground: 'primary lighten-1',
          dismissColor: 'primary lighten-1',
          actionColor: 'white',
          actionLight: true,
          dismissAction: true,
          dismissActionText: 'Mark as welcomed',
          actionButton: false,
        }
      }

      if (props.notification.type === 'thresholdBreach') {
        return {
          icon: 'mdi-alert-circle',
          cardDark: false,
          cardColor: 'yellow ',
          avatarBackground: 'yellow darken-1',
          dismissColor: 'yellow lighten-1',
          actionColor: 'white',
          actionLight: true,
          dismissAction: false,
          dismissActionText: 'Dismiss',
          actionButton: true,
        }
      }

      if (props.notification.type === 'careTeamReview') {
        return {
          icon: 'mdi-flag',
          cardDark: true,
          cardColor: 'primary',
          avatarBackground: 'primary lighten-1',
          dismissColor: 'primary lighten-1',
          actionColor: 'white',
          actionLight: true,
          dismissAction: true,
          dismissActionText: 'Reviewed',
          actionButton: false,
        }
      }

      if (props.notification.type === '@mention') {
        return {
          icon: 'mdi-at',
          cardDark: true,
          cardColor: 'primary darken-1',
          avatarBackground: 'primary lighten-1',
          dismissColor: 'primary lighten-1',
          actionColor: 'white',
          actionLight: true,
          dismissAction: true,
          dismissActionText: 'Reviewed',
          actionButton: false,
        }
      }

      if (props.notification.type === 'followUp') {
        return {
          icon: 'mdi-calendar',
          cardDark: true,
          cardColor: 'primary darken-1',
          avatarBackground: 'primary lighten-1',
          dismissColor: 'primary lighten-1',
          actionColor: 'white',
          actionLight: true,
          dismissAction: true,
          dismissActionText: 'Reviewed',
          actionButton: false,
        }
      }

      return {
        icon: 'mdi-alert-circle',
        cardDark: true,
        cardColor: 'primary',
        avatarBackground: 'primary lighten-1',
        dismissColor: 'primary lighten-1',
        actionColor: 'white',
        actionLight: true,
      }
    })

    return { cardDetails }
  },
})
</script>
