<template>
  <v-toolbar-items>
    <v-btn v-if="patient" style="margin-left: -18px; font-size: 14px;" depressed color="primary" @click="goToInfo()">
      <div>
        <v-row no-gutters justify="start">
          <div style="font-weight:bold">
            {{ patient.firstName.toUpperCase() }}
            {{ patient.lastName.toUpperCase() }}
          </div>
        </v-row>
        <v-row no-gutters justify="start">
          <span style="opacity: 0.7; font-size: 12px; text-align: left"
            >{{ patient.gender.charAt(0) }} {{ patient.age }} · {{ patient.org.patientId }}</span
          >
        </v-row>
      </div>
    </v-btn>
    <v-btn width="64" @click="goToDevices()" :color="devicesbuttonColor" text
      ><v-icon size="25">mdi-devices</v-icon></v-btn
    >
    <v-btn width="64" @click="goToFeed()" :color="feedButtonColor" icon><v-icon size="25">mdi-poll</v-icon></v-btn>

    <v-btn width="64" @click="goToMessaging()" :color="messagingButtonColor" icon
      ><v-icon size="25">mdi-message-text</v-icon></v-btn
    >
  </v-toolbar-items>
</template>

<script>
export default {
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  methods: {
    goToInfo() {
      if (this.$route.name !== 'PatientInfo') {
        this.$router.push({
          name: 'PatientInfo',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToFeed() {
      if (this.$route.name !== 'PatientFeed') {
        this.$router.push({ name: 'PatientFeed', params: { id: this.$route.params.id } })
      }
    },
    goToMessaging() {
      if (this.$route.name !== 'Messages') {
        this.$router.push({
          name: 'Messages',
          params: { id: this.$route.params.id },
        })
      }
    },
    goToDevices() {
      if (this.$route.name !== 'Devices') {
        this.$router.push({
          name: 'Devices',
          params: { id: this.$route.params.id },
        })
      }
    },
  },
  computed: {
    infoButtonColor() {
      if (this.$route.name === 'PatientInfo') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    feedButtonColor() {
      if (this.$route.name === 'PatientFeed') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    messagingButtonColor() {
      if (this.$route.name === 'Messages') {
        return 'primary'
      }
      return 'grey darken-2'
    },
    devicesbuttonColor() {
      if (this.$route.name === 'Devices') {
        return 'primary'
      }
      return 'grey darken-2'
    },
  },
}
</script>
