<template>
  <v-container v-if="patient" :key="chartKey" fluid fill-height :class="patientClass" style="align-items: start">
    <portal to="appBarContentLeft">
      <patient-chart-tabs :patient="patient" />
    </portal>

    <portal to="appBarContentRight">
      <v-row justify="end" align="center" no-gutters>
        <patient-action-bar :patient="patient" />
        <portal-target name="videoTimer" slim />
        <patient-chart-timer />
      </v-row>
    </portal>

    <router-view></router-view>

    <patient-messenger-popup @messengerOpen="messengerToggle" dark collapsable closeable />

    <snackbar-sms-required />

    <v-navigation-drawer color="transparent" permanent floating app clipped right>
      <div class="pr-4">
        <div v-if="completeNotificationCards.length > 0">
          <inbox-notification-card
            @handleDismiss="handleDismiss(notification)"
            @handleAction="handleAction(notification)"
            class="mb-3"
            v-for="(notification, index) in completeNotificationCards"
            :key="index"
            :notification="notification"
          />
        </div>

        <patient-activity-calendar />
      </div>
    </v-navigation-drawer>

    <add-note-dialog ref="AddNoteDialog" :dialogOpen="showNewNoteDialog" />
    <new-patient-note :messengerOpen="messengerOpen" ref="newNotePanel" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PatientChartTabs from '@/components/PatientChartTabs.vue'
import PatientChartTimer from '@/components/PatientChartTimer.vue'
import SnackbarSmsRequired from '@/components/snackbars/SnackbarSmsRequired.vue'
import PatientMessengerPopup from '@/components/popups/PatientMessengerPopup.vue'
import bus from '@/core/helpers/bus'
import PatientActivityCalendar from '@/components/PatientActivityCalendar.vue'
import InboxNotificationCard from '@/components/rpminbox/InboxNotificationCard.vue'
import AddNoteDialog from '@/components/dialogs/AddNoteDialog.vue'
import NewPatientNote from '@/components/bridgechartnotes/NewPatientNote.vue'
import PatientActionBar from '@/components/PatientActionBar.vue'

export default {
  components: {
    PatientChartTabs,
    PatientChartTimer,
    PatientMessengerPopup,
    SnackbarSmsRequired,
    PatientActivityCalendar,
    InboxNotificationCard,
    AddNoteDialog,
    NewPatientNote,
    PatientActionBar,
  },
  data: () => {
    return {
      messengerOpen: false,
      chartKey: 0,
      showNewNoteDialog: false,
      patientId: null,
      leavingRoute: false, // used to fix edge case: patient route redirects to inbox if patient is not loaded, but when leaving patient route, don't redirect
    }
  },

  computed: {
    ...mapState('patient', ['patient']),
    ...mapState('auth', ['user']),
    ...mapState('org', ['patientsLoading', 'usersDict']),
    ...mapState('inbox', ['orgNotificationsByPatient']),
    completeNotificationCards() {
      let prelim = []
      if (this.orgNotificationsByPatient[this.patient.id]) {
        this.orgNotificationsByPatient[this.patient.id].forEach(card => {
          if (card.type === '@mention' || card.type === 'careTeamReview' || card.type === 'followUp') {
            card.text = this.generateNoteForDisplay(this.patient, card.notificationForClearing).text
          }
          prelim.push(card)
        })
      }

      return prelim
    },
    patientClass() {
      return { 'pa-0': this.$route.name === 'Messages' }
    },
    trigger() {
      return { patientId: this.patientId, patientsLoading: this.patientsLoading }
    },
  },
  methods: {
    ...mapActions('patient', ['ackAlert', 'loadPatient', 'updateTags']),
    ...mapActions('patient', ['addNote', 'addNoteComment']),
    messengerToggle(val) {
      if (val) {
        this.messengerOpen = true
      } else {
        this.messengerOpen = false
      }
    },
    generateNoteForDisplay(patientObject, notification) {
      if (patientObject.rpm.notes.length > 0) {
        let timestampOfCorrectNote = notification.ts
        let latestNote = patientObject.rpm.notes[0]

        if (latestNote.ts !== timestampOfCorrectNote) {
          patientObject.rpm.notes.forEach(note => {
            if (note.ts === timestampOfCorrectNote) {
              latestNote = note
            }
          })
        }

        if (notification.comment) {
          if (latestNote.comments.length > 0) {
            latestNote = latestNote.comments[0]
          }
        }
        let displayObject = { text: 'Note', author: 'Author', date: 'date' }

        if (this.usersDict[latestNote.userId]) {
          displayObject.author =
            this.usersDict[latestNote.userId].firstName + ' ' + this.usersDict[latestNote.userId].lastName
        }
        displayObject.text = displayObject.author + ': ' + latestNote.text
        displayObject.date = notification.notificationTimestamp
        return displayObject
      }

      return null
    },
    handleLinkClick(content) {
      bus.$emit('messenger:open', { patient: this.patient, content })
    },

    async handleDismiss(notification) {
      if (notification.type === 'thresholdBreach') {
        for (const eventId of notification.payload || []) {
          await this.ackAlert(eventId)
          bus.$emit('toast', { type: 'success', text: 'Alert Acknowledged' })
        }
        this.patient.unseenAlert = false
      }

      if (notification.type === 'careTeamReview') {
        // just dismiss this?
        console.log('dismissing care team review', notification)
        await this.addNoteComment({
          noteUserId: notification.notificationForClearing.noteAuthor,
          noteTs: notification.notificationForClearing.ts,
          text: 'Reviewed',
          dismissCareTeamReview: notification.notificationForClearing,
        })
      }

      if (notification.type === '@mention') {
        console.log('handling mention', notification)
        console.log('dismissing mention', notification)
        await this.addNoteComment({
          noteUserId: notification.notificationForClearing.noteAuthor,
          noteTs: notification.notificationForClearing.ts,
          text: 'Reviewed',
          dismissMention: notification.notificationForClearing,
        })
      }

      if (notification.type === 'followUp') {
        await this.addNoteComment({
          noteUserId: notification.notificationForClearing.noteAuthor,
          noteTs: notification.notificationForClearing.ts,
          text: 'Reviewed',
          dismissTickler: notification.notificationForClearing,
        })
      }

      if (notification.type === 'newPatient') {
        await this.addNote({
          text: 'Marked as welcomed',
          tags: ['welcome'],
          userMentions: null,
          alertsToClear: null,
        })
        this.patient.tags.push('welcomed')

        try {
          await this.updateTags(this.patient.tags)
          bus.$emit('toast', { type: 'success', text: 'Patient Welcomed' })
        } catch (error) {
          console.log(error)
          bus.$emit('toast', { type: 'error', text: 'Error Tagging Chart' })
        }
      }
    },
    handleAction(notification) {
      console.log('responding to action', notification)
      // this is usually just take a note, then clear whatever thing on submit
      // maybe you want to comment on the note?
      if (notification.type === 'thresholdBreach') {
        if (this.$route.name !== 'PatientFeed') {
          this.$router.push({ name: 'PatientFeed', params: { id: this.patient.id } }).then(() => {
            bus.$emit('newNote', notification)
          })
        } else {
          bus.$emit('newNote', notification)
        }
      }

      if (notification.type === 'careTeamReview') {
        // comment on this?
        console.log('')
      }

      if (notification.type === '@mention') {
        // comment on this?
        console.log('')
      }
    },
  },
  watch: {
    $route() {
      this.patientId = this.$route.params.id
    },
    patient() {
      this.chartKey = this.chartKey + 1
      if (!this.patient && !this.leavingRoute) {
        this.$router.push({ name: 'Inbox' })
      }
    },
    // triggered when either patientId or patientsLoading change.
    // sets the patient
    trigger() {
      if (this.patientsLoading) {
        return // do nothing for now, wait for patients to finish loading
      }
      this.loadPatient(this.patientId)
    },
  },
  beforeMount() {
    this.patientId = this.$route.params.id
  },
  beforeRouteLeave(to, from, next) {
    this.leavingRoute = true
    this.patientId = null
    next()
  },
  mounted() {
    bus.$on('newNote', data => {
      this.$refs.newNotePanel.openNewNote({ notePayload: data })
    })
    bus.$on('clearThresholdAlerts', thresholdAlertsToClear => {
      for (const eventId of thresholdAlertsToClear) {
        this.ackAlert(eventId)
        bus.$emit('toast', { type: 'success', text: 'Alert Acknowledged' })
      }
      this.patient.unseenAlert = true
    })
  },
  beforeDestroy() {
    bus.$off('newNote')
    bus.$off('clearThresholdAlerts')
  },
}
</script>
